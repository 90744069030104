import axiosClient from "lib/axios";

const useRequest = () => {
    const { makeRequest, makeRequestAuth } = axiosClient(
        process.env.REACT_APP_SERVICE_URL
    );
    return { makeRequest, makeRequestAuth };
};

export default useRequest;
