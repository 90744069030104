import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// the translations1
// (tip move them in a JSON file and import them)

i18n.use(Backend).use(initReactI18next).init({
    lng: "tr",
    fallbackLng: "tr",
});
