import axios from "lib/axios";

const { makeRequestAuth } = axios(process.env.REACT_APP_SERVICE_URL);

export class UserService {
  verifyToken = (token) =>
    makeRequestAuth("get", `/users/verify?token=${token}`);

  refreshToken = (token) =>
    makeRequestAuth("get", `/users/refresh?token=${token}`);
  getUserById = (id) => makeRequestAuth("get", "/users/user/" + id);
  updateUser = (id, data) =>
    makeRequestAuth("patch", `/users/user/${id}`, {}, data);

  getUsers = async (id, { page = 1, limit = 10, name = "", email = "" }) =>
    makeRequestAuth("get", `/companies/company/${id}/users`, {
      page: page || 1,
      limit: limit || 10,
      name: name || "",
      email: email || "",
    });

  changeCompany = async (company_id) =>
    makeRequestAuth("post", "/users/change-company", { company_id });

  inviteEmail = async (data) =>
    makeRequestAuth("post", "/users/invite", {}, data);
}
