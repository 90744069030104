import axios from "axios";
import { getLocalStorage } from "utils/helpers";

const axiosClient = (url, interceptors = true) => {
  const instance = axios.create({
    baseURL: url,
  });

  if (interceptors) {
    instance.interceptors.response.use(
      (res) => {
        if (!res) {
          throw new Error("Response is not found");
        }
        return res;
      },
      async (err) => {
        console.log(`[axios][interceptors][response][failure] ${err}`);
        throw err;
      }
    );
  }

  function makeRequest(type, path, queryParams = {}, body = {}, options = {}) {
    const cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token

    const requestConfig = {
      method: type,
      url: path,
      params: queryParams, // This is where the query parameters are set
      data: body, // For 'post', 'put', 'patch' requests
      headers: {
        Authorization: "public",
        ...options.headers, // Spread existing headers to include any additional headers
      },
      cancelToken: cancelTokenSource.token, // Add the cancel token to the request
      ...options, // Spread any additional axios config options
    };

    return new Promise((resolve, reject) => {
      instance(requestConfig)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
          } else {
            reject(err.response || err);
          }
        });
    });
  }

  async function makeRequestAuth(
    type,
    path,
    queryParams = {},
    body = {},
    options = {}
  ) {
    if (!localStorage.getItem("token")) return;

    const cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token

    return makeRequest(type, path, queryParams, body, {
      headers: {
        Authorization: `Bearer ${getLocalStorage("token")}`, // Use Bearer token authentication
        ...options.headers, // Ensure additional headers can be added or existing ones overridden
      },
      cancelToken: cancelTokenSource.token, // Add the cancel token to the request
      ...options, // Spread any additional axios config options
    });
  }

  return {
    instance,
    makeRequest,
    makeRequestAuth,
  };
};

export default axiosClient;