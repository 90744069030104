import { BrowserRouter, Routes, Route } from "react-router-dom";
import Components from "./Components";
import AuthRoute from "./Guards/AuthRoute";
import PublicRoute from "./Guards/PublicRoute";
import CustomSuspense from "components/UI/RenderElements/CustomSuspense";
import React, { lazy, useState, useEffect } from "react";
import useRequest from "hooks/useRequest";

const DashboardLayout = lazy(() => import("components/layout/Dashboard"));
const Router = () => {
    const [pageData, setPageData] = useState([]);

    const { makeRequest } = useRequest();

    useEffect(() => {
        makeRequest("get", "/routes")
            .then((data) => {
                if (data.length > 0) {
                    setPageData(data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                {pageData.map((route) => (
                    <Route
                        key={route.path}
                        element={
                            route.layout === "dashboard" ? (
                                <DashboardLayout />
                            ) : null
                        }
                    >
                        <Route
                            path={route.path}
                            key={route.path}
                            element={
                                route.public ? (
                                    <PublicRoute>
                                        <CustomSuspense>
                                            {Components[route.component]}
                                        </CustomSuspense>
                                    </PublicRoute>
                                ) : (
                                    <AuthRoute>
                                        <CustomSuspense>
                                            {Components[route.component]}
                                        </CustomSuspense>
                                    </AuthRoute>
                                )
                            }
                        ></Route>

                        {route.children &&
                            route.children.map((child) => (
                                <Route
                                    path={child.path}
                                    key={child.path}
                                    element={
                                        <AuthRoute>
                                            <CustomSuspense>
                                                {Components[child.component]}
                                            </CustomSuspense>
                                        </AuthRoute>
                                    }
                                />
                            ))}
                    </Route>
                ))}
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
