import { createSlice } from "@reduxjs/toolkit";
import { setLocalStorage } from "utils/helpers";
const initialState = {
  user: {
    id: null,
    name: null,
    email: null,
    role: null,
  },
  isAuth: false,
  company: {
    id: null,
    name: null,
  },
  companyExists: false,
  refreshState: false,
};
const User = createSlice({
  name: "user",
  initialState,
  reducers: {
    userModifiedData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    setUserInitialState: () => initialState,
    companyModifiedData: (state, action) => {
      state.company = action.payload.data;
    },
  },
});

export const { userModifiedData, setUserInitialState, companyModifiedData } =
  User.actions;
export default User.reducer;

export const updateUser =
  ({ token, user }) =>
  (dispatch) => {
    dispatch(userModifiedData({ name: "user", data: user }));
    dispatch(userModifiedData({ name: "isAuth", data: true }));
  };
export const updateCompany =
  ({ company }) =>
  (dispatch) => {
    company?.id &&
      dispatch(
        userModifiedData({ name: "companyExists", data: Boolean(company?.id) })
      );
    dispatch(companyModifiedData({ name: "company", data: company }));
  };

export const _refreshState =
  ({ refresh }) =>
  (dispatch) => {
    dispatch(userModifiedData({ name: "refreshState", data: refresh }));
  };
