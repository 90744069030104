import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PublicRoute({ children }) {
  const { token } = useSelector(({ auth }) => auth);
  let location = useLocation();

  return (
    <>
      {!token ? (
        children
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      )}
    </>
  );
}

export default PublicRoute;
